import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import arrowUp from "../assets/img/arrowUp3.webp";

const ButtonContainer = styled.span`
  position: fixed;
  bottom: 32px;
  right: 32px;
  align-items: center;
  height: 64px;
  width: 64px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  opacity: 1;
  background: #111;
  border-radius: 50%;
  ${'' /* transition: opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s; */}
  border-color: rgba(159, 8, 38, 1);
  color: #fff;
  ${'' /* box-shadow: 0 0 40px 40px #3498db inset, 0 0 0 0 #3498db; */}
  transition: all 150ms ease-in-out;
  display: ${({ isScrollButtonVisible }) =>
    isScrollButtonVisible ? 'flex' : 'none'};

  &:hover,&:focus {
    opacity: 1;
    ${'' /* background:rgb(255,0,0); */}
    box-shadow: 0 0 10px 0 rgba(159, 8, 38, 1) inset, 0 0 10px 4px rgba(159, 8, 38, 1);
    ${'' /* box-shadow: #e8b11f 2px 4px 80px; */}
    border: 1px solid #e8b11f;
  }
`;


const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const checkScrollHeight = () => {
      if (!showButton && window.pageYOffset > 400) {
        setShowButton(true);
      } else if (showButton && window.pageYOffset <= 400) {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', checkScrollHeight);
    return () => {
      window.removeEventListener('scroll', checkScrollHeight);
    };
  }, [showButton]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <ButtonContainer isScrollButtonVisible={showButton} onClick={scrollToTop}>
      <img src={arrowUp} height={50} width={50} alt="arrow Img"/>
    </ButtonContainer>
  );
};

export default BackToTopButton;