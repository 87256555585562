import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import firstImage from "../assets/img/EuropeanDeckOvenHBWO/FirstImage.webp";
import secondImage from "../assets/img/EuropeanDeckOvenHBWO/secondImage.webp";
import thirdImage from "../assets/img/EuropeanDeckOvenHBWO/ThirdImage.webp";
import fourthImage from "../assets/img/EuropeanDeckOvenHBWO/FourthImage.webp";
import SteamStart from "../assets/img/SteamStart.webp";
import EuropeanDeckBorchure from "../assets/img/EuropeanDeckBorchure.jpg";
import FirstModel from "../assets/img/firstModel.webp";
import SecondModel from "../assets/img/secondModel.webp";
import ThirdModel from "../assets/img/thirdModel.webp";
import FourthModel from "../assets/img/fourthModel.webp";
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SlateGermany from "../assets/img/EuropeanDeckOvenHBWO/slateGermany.webp";
import CustomizedSHEATHHEATER from "../assets/img/EuropeanDeckOvenHBWO/CustomizedSHEATHHEATER.webp";
import LOWETemperedGlass from "../assets/img/EuropeanDeckOvenHBWO/LOW-ETemperedGlass.webp";
import VTVIinsulationsystem from "../assets/img/EuropeanDeckOvenHBWO/VT&VIinsulationsystem.webp";
import USBInterfaces from "../assets/img/EuropeanDeckOvenHBWO/USBInterfaces.webp";

const ProductDetail = () => {
  const [sliderFor, setSliderFor] = useState(null);
  const [sliderNav, setSliderNav] = useState(null);

  const sliderForSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: sliderNav,
    className: "main-slider",
  };

  const sliderNavSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: sliderFor,
    dots: true,
    focusOnSelect: true,
    className: "nav-slider",
  };

  const handleSlideClick = (slideno) => {
    sliderNav.slickGoTo(slideno - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <Container style={{ marginTop: "20px", padding: "20px" }}>
      <Row className="justify-content-md-center">
        <Col className="overview-container" md={12}>
          <h2
            style={{
              color: "#9f0826",
              fontFamily: "Holtwood One SC",
              textAlign: "center",
              margin: "20px 0",
              // background: "red",
              fontSize: "3rem",
            }}
          >
            European [W] Deck Oven HBWO
          </h2>
          <p
            style={{
              fontSize: "16px",
              textAlign: "justify",
              marginTop: "10px",
              color: "#9f0826",
              fontFamily: "verdana",
            }}
          >
            Imported Stone Slab, STEAM Heating System, VT&VI Insulation System
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "justify",
              marginTop: "10px",
              fontFamily: "Holtwood One SC",
            }}
          >
            The HBWO model is the fantastic deck oven for artisanal bakers.
            Optimum for baking the entire product range, from heavy rye breads
            to wheat rolls. HBWO Ovens stand out the advantages of lower power
            consumption, convenient programmed control, low maintenance,
            durability and easy operation.
          </p>
        </Col>
        <Col md={12} className="product-image overview-container">
          <h1 style={{ fontWeight: "bolder" }} className="section-title">
            IMAGES
          </h1>
          <div>
            <Slider
              {...sliderForSettings}
              ref={(slider) => setSliderFor(slider)}
            >
              <div className="slickSlide">
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div className="slickSlide">
                <img src={secondImage} alt="Slide 2" />
              </div>
              <div className="slickSlide">
                <img src={thirdImage} alt="Slide 3" />
              </div>
              <div className="slickSlide">
                <img src={fourthImage} alt="Slide 4" />
              </div>
            </Slider>
            <Slider
              {...sliderNavSettings}
              ref={(slider) => setSliderNav(slider)}
            >
              <div onClick={() => handleSlideClick(1)}>
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div onClick={() => handleSlideClick(2)}>
                <img src={secondImage} alt="Slide 2" />
              </div>
              <div onClick={() => handleSlideClick(3)}>
                <img src={thirdImage} alt="Slide 3" />
              </div>
              <div onClick={() => handleSlideClick(4)}>
                <img src={fourthImage} alt="Slide 4" />
              </div>
            </Slider>
          </div>
        </Col>
        <Col md={12} className="overview-container">
          <h2>OVERVIEW</h2>
          <div className="bullet-point advanced-tech">
            Independent STEAM Heating System, instantly generated abundant
            quantity of steam, bread with best sheen.
          </div>
          <div className="bullet-point matrix-heating">
            Baking on optional Imported Stone Slab, baked products more crunchy.
          </div>
          <div className="bullet-point">
            Matrix Heating System with Far Infrared IBS technology, shorten
            baking time
          </div>
          <div className="bullet-point">
            Unique using LOW-E Tempered Glass, rediate heat inside chamber,
            avoiding heat loss.
          </div>
          <div className="bullet-point">
            VT&VI Fully welded nano thermal insulation system, Modular design
            with perfect insulation.
          </div>
          <div className="divider"></div>

          {/* New section */}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">
                Advanced Independent STEAM Heating System
              </h1>
              <div className="section-text">
                <p>
                  <span className="bold">
                    {" "}
                    Advanced Independent STEAM Heating System,{" "}
                  </span>
                  thanks to
                  <span className="bold"> Heating Rod Technology, </span>
                  much more energy-saving and without any malfunction. It’s
                  option to choose standard
                  <span className="bold"> separate stream generator </span>
                  for all the decks. The steam generators are thermostatically
                  controlled, the water transformed into tiny droplets by using
                  compressed air and producing
                  <span className="bold">
                    {" "}
                    abundant quantity of high-pressure saturated steam{" "}
                  </span>
                  economically, and rapidly to make the
                  <span className="bold"> bread with best sheen. </span>
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            >
              {/* Place your image component here */}
              <img src={SteamStart} alt="Your Image" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 2*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Slate imported from Germany</h1>
              <div className="section-text">
                <p>
                  Each chamber is option for the
                  <span className="bold"> Stone Slab </span>
                  which is
                  <span className="bold"> imported from Germany. </span>
                  The stone slab is made of
                  <span className="bold">
                    {" "}
                    high temperature resistant material,{" "}
                  </span>
                  it avoids temperature dropping when opening the chamber in a
                  short time, also shorten the baking time. Stone slabs can
                  <span className="bold"> absorbing water </span>
                  at the bottom of the dough, which avoiding the dough becomes
                  dry and hard, lead to excessive moisture loss. Baking on the
                  stone, the bread would be
                  <span className="bold">
                    {" "}
                    more crunchy and with perfect sheen.{" "}
                  </span>
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            >
              {/* Place your image component here */}
              <img
                style={{ width: "45%" }}
                src={SlateGermany}
                alt="BootPpointment img"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 3*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Customized SHEATH HEATER</h1>
              <div className="section-text">
                <p>
                  <span className="bold">
                    {" "}
                    SHEATH HEATER with Ceramic Coating,{" "}
                  </span>
                  Superior Quality and combined with advanced
                  <span className="bold"> IBS technology </span>( Far Infrared
                  Enamel Treatment), equipped with
                  <span className="bold"> Matrix Heating System </span>
                  creating a greater heating surface area to guarantee
                  <span className="bold"> uniform baking. </span>
                  The heat wires
                  <span className="bold"> shorten the baking time </span>
                  by 20%~50%, baking time of 100g bread has been shortened 2
                  minutes. More energy-saving compared to other brand ovens. The
                  heat is released uniformly throughout the baking chamber and
                  delicately bakes the product without being over burnt.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            >
              {/* Place your image component here */}
              <img src={CustomizedSHEATHHEATER} alt="ConfigurableHeating img" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 4*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">LOW-E Tempered Glass</h1>
              <div className="section-text">
                <p>
                  Unparalleled merit of Bresso oven is equipped with
                  <span className="bold"> LOW-E Tempered Glass, </span>
                  heat inside the oven will not dissipate to outside,
                  <span className="bold">
                    {" "}
                    gather all the heat inside the chamber{" "}
                  </span>
                  to shorten the baking time and achieving energy-saving effect.
                  Under baking status the heat from Low-E glass will not let you
                  feel too hot to approach.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            >
              {/* Place your image component here */}
              <img src={LOWETemperedGlass} alt="NichromeWire img" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 5*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">VT&VI insulation system</h1>
              <div className="section-text">
                <p>
                  <span className="bold">
                    {" "}
                    VT&VI Fully welded nano thermal insulation system,{" "}
                  </span>
                  using dissolv able environmental friendly insulation
                  materials, increase the oven insulation performance by 30%,
                  extend the life of the oven. Bresso oven meets all
                  requirements you need, economic efficiency and flexibility.
                  Each deck is separately
                  <span className="bold"> modular design </span>
                  Modular design has its advantage of
                  <span className="bold"> perfect Insulation, </span>
                  it avoids transmitting the heat to the other chambers which
                  will influence the baking result. We choose optimum aluminum
                  plate materials,
                  <span className="bold"> excellent sealing performance </span>
                  with full welding process.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            >
              {/* Place your image component here */}
              <img src={VTVIinsulationsystem} alt="LowETempered img" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 6*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">USB interfaces</h1>
              <div className="section-text">
                <p>
                  USB interfaces supports storing 60 baking programs with bread
                  pictures in the front panel. It’s easy operation, set up
                  <span className="bold">
                    {" "}
                    60 kinds of bread baking programs{" "}
                  </span>
                  and preserve them in the USB, then when you baking, just
                  choose the bread picture you need to baking, realizing
                  <span className="bold"> smart baking. </span>
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            >
              {/* Place your image component here */}
              <img src={USBInterfaces} alt="VTVI img" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 7*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">SPECIFICATION</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section-borchure"
            >
              {/* Place your image component here */}
              <img src={EuropeanDeckBorchure} alt="EuropeanDeckBorchure img" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 8*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">MODEL</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section-model"
            >
              {/* Place your image component here */}
              <img src={FirstModel} alt="FirstModel img" />
              <img src={SecondModel} alt="SecondModel img" />
              <img src={ThirdModel} alt="ThirdModel img" />
              <img src={FourthModel} alt="FourthModel img" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 9*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">VIDEO</h1>
            </div>
            <div
              className="video-container"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <ReactPlayer
                url="https://www.youtube.com/watch?v=-jDyMj1Cg_E"
                style={{ marginTop: "30px" }}
                controls
                playing
                muted
                loop
                width="100%"
                height="600px"
              />
            </div>
          </div>
          <div className="divider"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetail;
