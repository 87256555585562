import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import firstImage from "../assets/img/KneadingMachine/FirstImage.webp";
import secondImage from "../assets/img/KneadingMachine/SecondImage.webp";
import KneadingMachineSpecification from "../assets/img/KneadingMachine/Specification.png";
import FirstModel from "../assets/img/convectionOven/model1.webp";
import SecondModel from "../assets/img/convectionOven/model2.webp";
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const ProductDetail = () => {
  const [sliderFor, setSliderFor] = useState(null);
  const [sliderNav, setSliderNav] = useState(null);

  const sliderForSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: sliderNav,
    className: "main-slider",
  };

  const sliderNavSettings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    asNavFor: sliderFor,
    dots: true,
    focusOnSelect: true,
    className: "nav-slider",
  };

  const handleSlideClick = (slideno) => {
    sliderNav.slickGoTo(slideno - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <Container style={{ marginTop: "20px", padding: "20px" }}>
      <Row className="justify-content-md-center">
        <Col className="overview-container" md={12}>
          <h2
            style={{
              color: "#9f0826",
              fontFamily: "Holtwood One SC",
              textAlign: "center",
              margin: "20px 0",
              // background: "red",
              fontSize: "3rem",
            }}
          >
            HKPM Kneading Machine
          </h2>
          <p
            style={{
              fontSize: "16px",
              textAlign: "justify",
              marginTop: "10px",
              color: "#9f0826",
              fontFamily: "verdana",
            }}
          >
            25/50/100T mixer, Microcomputer digital display
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "justify",
              marginTop: "10px",
              fontFamily: "Holtwood One SC",
            }}
          >
            HKPM Kneading Machine is automatic spiral mixer, the ideal machine
            for bakeries and confectioners. It has three dough capacities for
            your option from 25kgs to 100kgs. HKPM mixer is made of thick
            stainless steel, high reliability with strong mechanical components.
          </p>
        </Col>
        <Col md={12} className="product-image overview-container">
          <h1 style={{ fontWeight: "bolder" }} className="section-title">
            IMAGES
          </h1>
          <div>
            <Slider
              {...sliderForSettings}
              ref={(slider) => setSliderFor(slider)}
            >
              <div className="slickSlide">
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div className="slickSlide">
                <img src={secondImage} alt="Slide 2" />
              </div>
            </Slider>
            <Slider
              {...sliderNavSettings}
              ref={(slider) => setSliderNav(slider)}
            >
              <div onClick={() => handleSlideClick(1)}>
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div onClick={() => handleSlideClick(2)}>
                <img src={secondImage} alt="Slide 2" />
              </div>
            </Slider>
          </div>
        </Col>
        <Col md={12} className="overview-container">
          <h2>OVERVIEW</h2>
          <div className="bullet-point advanced-tech">
            Imported steel material and electrical components
          </div>

          <div className="bullet-point matrix-heating">
            25/50/100T mixer, body color has pure white, silver gray and milky
            white for option
          </div>

          <div className="bullet-point">
            Microcomputer digital display control panel
          </div>

          <div className="bullet-point">
            Suitable for mixing all kinds of bread and pizza dough
          </div>
          <div className="bullet-point">
            Using excellent Motor, High Torque, low noise, imported original
            belt
          </div>

          <div className="divider"></div>

          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Product Details</h1>
            </div>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              className="productDetails-section"
            >
              <ul>
                <li className="productDetails-list">
                  Visible belt swelling system, easy to remove the belt pulley
                  and convenient maintenance.
                </li>
                <li>
                  Using imported steel material and electrical components,
                  equipped with overload protection function, enhance the
                  equipment lifespan.
                </li>
                <li className="productDetails-list">
                  25/50/100T mixer, body color has pure white, silver gray and
                  milky white for option.
                </li>
                <li className="productDetails-list">
                  Using excellent Motor, High Torque, low noise, imported
                  original belt to ensure the dough Oxygen content.
                </li>
                <li className="productDetails-list">
                  Rotating forward and reverse of Kneading bucket are optional
                  for over load, Phase failure protection device and protective
                  cover safety linkage device.
                </li>
                <li className="productDetails-list">
                  Microcomputer digital display control panel, automatic program
                  setting, manual operation mode, luxurious and beautiful, easy
                  to operate.
                </li>
                <li className="productDetails-list">
                  High water absorption, good dough strength, high expansion
                  rate entering, reducing cost.
                </li>
                <li className="productDetails-list">
                  Suitable for mixing all kinds of bread and pizza dough,
                  professionally designed to avoid excessive heating of the
                  dough
                </li>
                <li className="productDetails-list">
                  The part in contact with the dough is made of stainless steel,
                  which meets the current hygienic standards. The stirring rod
                  is optional with two speeds.
                </li>
                <li className="productDetails-list">
                  Caster has a locking device, electrical safety conforms to
                  international GB4706.38-2003 ( Equal to International Standard
                  IEC60335-2-64:1997 )
                </li>
              </ul>
            </div>
          </div>
          <div className="divider"></div>

          {/* New section 7*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">SPECIFICATION</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section-borchure"
            >
              {/* Place your image component here */}
              <img
                src={KneadingMachineSpecification}
                alt="KneadingMachineSpecification img"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 9*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">VIDEO</h1>
            </div>
            <div
              className="video-container"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <ReactPlayer
                url="https://d1c6gk3tn6ydje.cloudfront.net/1513135116833038336%2Fe1152d45f8acd44eabd0066199366bca.mp4"
                style={{ marginTop: "30px" }}
                controls
                muted
                loop
                width="100%"
                height="600px"
              />
            </div>
          </div>
          <div className="divider"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetail;
