import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import firstImage from "../assets/img/convectionOven/FirstImage.webp";
import secondImage from "../assets/img/convectionOven/SecondImage.webp";
import thirdImage from "../assets/img/convectionOven/ThirdImage.webp";
import fourthImage from "../assets/img/convectionOven/FourthImage.webp";
import ConvectionOvenSpecification from "../assets/img/convectionOven/Specification.png";
import FirstModel from "../assets/img/convectionOven/model1.webp";
import SecondModel from "../assets/img/convectionOven/model2.webp";
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const ProductDetail = () => {
  const [sliderFor, setSliderFor] = useState(null);
  const [sliderNav, setSliderNav] = useState(null);

  const sliderForSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: sliderNav,
    className: "main-slider",
  };

  const sliderNavSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: sliderFor,
    dots: true,
    focusOnSelect: true,
    className: "nav-slider",
  };

  const handleSlideClick = (slideno) => {
    sliderNav.slickGoTo(slideno - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <Container style={{ marginTop: "20px", padding: "20px" }}>
      <Row className="justify-content-md-center">
        <Col className="overview-container" md={12}>
          <h2
            style={{
              color: "#9f0826",
              fontFamily: "Holtwood One SC",
              textAlign: "center",
              margin: "20px 0",
              // background: "red",
              fontSize: "3rem",
            }}
          >
            HCV Convection Oven
          </h2>
          <p
            style={{
              fontSize: "16px",
              textAlign: "justify",
              marginTop: "10px",
              color: "#9f0826",
              fontFamily: "verdana",
            }}
          >
            Chic design, Advanced STEAM System, Smart PCB
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "justify",
              marginTop: "10px",
              fontFamily: "Holtwood One SC",
            }}
          >
            HCV Convection Oven with luxurious design, the best performance in
            the Korean baking industry. The optimum choice for baking French
            bread, cream bread, biscuits, pies and other European bread.
          </p>
        </Col>
        <Col md={12} className="product-image overview-container">
          <h1 style={{ fontWeight: "bolder" }} className="section-title">
            IMAGES
          </h1>
          <div>
            <Slider
              {...sliderForSettings}
              ref={(slider) => setSliderFor(slider)}
            >
              <div className="slickSlide">
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div className="slickSlide">
                <img src={secondImage} alt="Slide 2" />
              </div>
              <div className="slickSlide">
                <img src={thirdImage} alt="Slide 3" />
              </div>
              <div className="slickSlide">
                <img src={fourthImage} alt="Slide 4" />
              </div>
            </Slider>
            <Slider
              {...sliderNavSettings}
              ref={(slider) => setSliderNav(slider)}
            >
              <div onClick={() => handleSlideClick(1)}>
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div onClick={() => handleSlideClick(2)}>
                <img src={secondImage} alt="Slide 2" />
              </div>
              <div onClick={() => handleSlideClick(3)}>
                <img src={thirdImage} alt="Slide 3" />
              </div>
              <div onClick={() => handleSlideClick(4)}>
                <img src={fourthImage} alt="Slide 4" />
              </div>
            </Slider>
          </div>
        </Col>
        <Col md={12} className="overview-container">
          <h2>OVERVIEW</h2>
          <div className="bullet-point advanced-tech">
            Chic design with perfect appearance
          </div>

          <div className="bullet-point matrix-heating">
            Advanced STEAM System
          </div>

          <div className="bullet-point">Full touch control system display</div>

          <div className="bullet-point">
            Clockwise/anti-clockwise rotating fan
          </div>

          <div className="divider"></div>

          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Product Details</h1>
            </div>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              className="productDetails-section"
            >
              <ul>
                <li className="productDetails-list">
                  Steam with advanced steam spraying technology and core
                  temperature sensor.
                </li>
                <li>Full touch control system display.</li>
                <li className="productDetails-list">
                  The main accessories are imported from Europe, the first
                  convection oven ranked in South Korea.
                </li>
                <li className="productDetails-list">
                  Clockwise/anti-clockwise rotating fan, uniform baked product
                  color.
                </li>
                <li className="productDetails-list">
                  Luxurious design, the best performance in the Korean baking
                  industry.
                </li>
                <li className="productDetails-list">
                  Accurate temperature sensor and with the PCB operation panel,
                  easy to operate.
                </li>
                <li className="productDetails-list">
                  The best choice for baking French bread, cream bread,
                  biscuits, pies, and other European bread.
                </li>
                <li className="productDetails-list">
                  Baked goods are perfectly surrounded by hot air in the baking
                  chamber, ensuring a perfect baking result.
                </li>
                <li className="productDetails-list">
                  The optimized insulation function, excellent energy-saving
                  performance.
                </li>
                <li className="productDetails-list">
                  The storage compartment is available according to your
                  requirement.
                </li>
              </ul>
            </div>
          </div>
          <div className="divider"></div>

          {/* New section 7*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">SPECIFICATION</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section-borchure"
            >
              {/* Place your image component here */}
              <img
                src={ConvectionOvenSpecification}
                alt="ConvectionOvenSpecification img"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 8*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">MODEL</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section-model"
            >
              {/* Place your image component here */}
              <img
                style={{ width: "20%", height: "80%", marginRight: "20px" }}
                src={FirstModel}
                alt="FirstModel img"
              />
              <img
                style={{ width: "20%", height: "80%", marginLeft: "20px" }}
                src={SecondModel}
                alt="SecondModel img"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 9*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">VIDEO</h1>
            </div>
            <div
              className="video-container"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <ReactPlayer
                url="https://d1c6gk3tn6ydje.cloudfront.net/1513135116833038336%2Fe1152d45f8acd44eabd0066199366bca.mp4"
                style={{ marginTop: "30px" }}
                controls
                playing
                muted
                loop
                width="100%"
                height="600px"
              />
            </div>
          </div>
          <div className="divider"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetail;
