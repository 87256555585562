import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import firstImage from "../assets/img/bakeryDeckOven/first.webp";
import secondImage from "../assets/img/bakeryDeckOven/second.webp";
import thirdImage from "../assets/img/bakeryDeckOven/third.webp";
import fourthImage from "../assets/img/bakeryDeckOven/fourth.webp";
import fifthImage from "../assets/img/bakeryDeckOven/fifth.webp";
import SteamStart from "../assets/img/SteamStart.webp";
import BootPpointment from "../assets/img/BootAppointment.webp";
import ConfigurableHeating from "../assets/img/ConfigurableHeating.webp";
import NichromeWire from "../assets/img/NichromeWire.webp";
import LowETempered from "../assets/img/LowETempared.webp";
import VTVI from "../assets/img/VTVI.webp";
import EuropeanDeckBorchure from "../assets/img/EuropeanDeckBorchure.jpg";
import FirstModel from "../assets/img/firstModel.webp";
import SecondModel from "../assets/img/secondModel.webp";
import ThirdModel from "../assets/img/thirdModel.webp";
import FourthModel from "../assets/img/fourthModel.webp";
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./productDetail.css";

const ProductDetail = () => {
  const [sliderFor, setSliderFor] = useState(null);
  const [sliderNav, setSliderNav] = useState(null);

  const sliderForSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: sliderNav,
    className: "main-slider",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  const sliderNavSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: sliderFor,
    dots: true,
    focusOnSelect: true,
    className: "nav-slider",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleSlideClick = (slideno) => {
    sliderNav.slickGoTo(slideno - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <Container style={{ marginTop: "20px", padding: "20px" }}>
      <Row className="justify-content-md-center">
        <Col className="overview-container" md={12}>
          <h2 className="title">Bakery Deck Oven HBDO</h2>
          <p className="sub-title">
            STEAM Heating System, Far Infrared IBS technology, LOW-E Tempered
            Glass.
          </p>
          <p className="description">
            HBDO Deck ovens bring flexibility and reliability for
            quality-oriented bakers, offering the best baking capacity in the
            smallest space. Thanks to equally radiated heat and a static baking
            atmosphere, with excellent baking results of crisp crusts, succulent
            crumb, crust crack.
          </p>
        </Col>
        <Col md={12} className="product-image overview-container">
          <h1 className="section-title">IMAGES</h1>
          <div>
            <Slider
              {...sliderForSettings}
              ref={(slider) => setSliderFor(slider)}
            >
              <div className="slickSlide">
                <img src={firstImage} alt="Slide 1" className="img-fluid" />
              </div>
              <div className="slickSlide">
                <img src={secondImage} alt="Slide 2" className="img-fluid" />
              </div>
              <div className="slickSlide">
                <img src={thirdImage} alt="Slide 3" className="img-fluid" />
              </div>
              <div className="slickSlide">
                <img src={fourthImage} alt="Slide 4" className="img-fluid" />
              </div>
              <div className="slickSlide">
                <img src={fifthImage} alt="Slide 5" className="img-fluid" />
              </div>
            </Slider>
            <Slider
              {...sliderNavSettings}
              ref={(slider) => setSliderNav(slider)}
            >
              <div onClick={() => handleSlideClick(1)}>
                <img src={firstImage} alt="Slide 1" className="img-fluid" />
              </div>
              <div onClick={() => handleSlideClick(2)}>
                <img src={secondImage} alt="Slide 2" className="img-fluid" />
              </div>
              <div onClick={() => handleSlideClick(3)}>
                <img src={thirdImage} alt="Slide 3" className="img-fluid" />
              </div>
              <div onClick={() => handleSlideClick(4)}>
                <img src={fourthImage} alt="Slide 4" className="img-fluid" />
              </div>
              <div onClick={() => handleSlideClick(5)}>
                <img src={fifthImage} alt="Slide 5" className="img-fluid" />
              </div>
            </Slider>
          </div>
        </Col>
        <Col md={12} className="overview-container">
          <h2>OVERVIEW</h2>
          <div className="bullet-point advanced-tech">
            Advanced technology of STEAM Heating System, baked bread with best
            sheen.
          </div>
          <div className="bullet-point matrix-heating">
            Matrix Heating System with Far Infrared IBS technology, shorten the
            baking time.
          </div>
          <div className="bullet-point">
            Unique using LOW-E Tempered Glass, radiate heat inside the chamber,
            avoiding heat loss.
          </div>
          <div className="bullet-point">
            VT&VI Fully welded nano thermal insulation system, Modular design
            with perfect insulation.
          </div>
          <div className="bullet-point">
            Configurable heating levels, best recyclable and insulation
            materials, optimize Energy resource usage.
          </div>
          <div className="divider"></div>

          {/* New section */}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">
                Unique Integrated Steam Heating System
              </h1>
              <div className="section-text">
                <p>
                  Thanks to{" "}
                  <span className="bold">Integrated Heating technology</span>,
                  much more <span className="bold">Energy-saving</span>. Option
                  to choose standard separate steam generator. The steam
                  generators are thermostatically controlled, producing{" "}
                  <span className="bold">
                    abundant quantity of high-pressure saturated steam
                  </span>{" "}
                  economically, and rapidly to make the bread with{" "}
                  <span className="bold">best sheen</span>.
                </p>
              </div>
            </div>
            <div className="image-section">
              <img src={SteamStart} alt="Your Image" className="img-fluid" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 2*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Boot appointment function</h1>
              <div className="section-text">
                <p>
                  Standard oven, it can realize{" "}
                  <span className="bold"> Boot appointment function</span>,
                  before leaving the workshop, when you back to the shop next
                  morning, Bresso oven has been{" "}
                  <span className="bold">preheated</span> according to the time
                  and temperature setting, just looking forward to your baking
                  operation.
                </p>
              </div>
            </div>
            <div className="image-section">
              <img
                src={BootPpointment}
                alt="BootPpointment img"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 3*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Configurable Heating Levels</h1>
              <div className="section-text">
                <p>
                  Bresso ovens own{" "}
                  <span className="bold">
                    {" "}
                    efficient configurable heating levels,
                  </span>
                  , firepower of{" "}
                  <span className="bold">large fire, medium fire</span> and{" "}
                  <span className="bold">small fire.</span>
                  Our systems optimize Energy resource usage, avoiding energy
                  losses. Besides we use{" "}
                  <span className="bold">
                    recyclable and insulation materials,
                  </span>{" "}
                  equipped with{" "}
                  <span className="bold">
                    modern control units, digital data management
                  </span>{" "}
                  to realize perfect energy efficiency.
                </p>
              </div>
            </div>
            <div className="image-section">
              <img
                src={ConfigurableHeating}
                alt="ConfigurableHeating img"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 4*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Nichrome Wire Heater</h1>
              <div className="section-text">
                <p>
                  <span className="bold"> NICHROME WIRE HEATER, </span>
                  imported from Japan with high quality and combined with
                  <span className="bold">
                    {" "}
                    Advanced IBS technology (Far Infrared Enamel Treatment),{" "}
                  </span>
                  equipped with
                  <span className="bold"> Matrix Heating System </span>
                  creating a greater heating surface area to guarantee uniform
                  baking. The wire heater
                  <span className="bold"> shortens the baking time </span>
                  by 20%~50%, baking time of 100g bread has been shortened 2
                  minutes. More
                  <span className="bold"> Energy-saving </span>
                  compared to other brand ovens.
                </p>
              </div>
            </div>
            <div className="image-section">
              <img
                src={NichromeWire}
                alt="NichromeWire img"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 5*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">LOW-E Tempered Glass</h1>
              <div className="section-text">
                <p>
                  Unparalleled merit of Bresso oven is equipped with
                  <span className="bold"> LOW-E Tempered Glass, </span>
                  heat inside the oven will not dissipate to outside,
                  <span className="bold">
                    {" "}
                    gather all the heat inside the chamber{" "}
                  </span>
                  to shorten the baking time and achieving energy-saving effect.
                  Under baking status the heat from Low-E glass will not let you
                  feel too hot to approach.
                </p>
              </div>
            </div>
            <div className="image-section">
              <img
                src={LowETempered}
                alt="LowETempered img"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 6*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">
                VT&VI Fully Welded Nano Thermal Insulation System
              </h1>
              <div className="section-text">
                <p>
                  <span className="bold">
                    {" "}
                    VT&VI Fully Welded Nano Thermal Insulation System,{" "}
                  </span>
                  economic efficiency and flexibility. Each deck is separately
                  modular design, 1-4 decks stack one above the other.{" "}
                  <span className="bold"> Modular design </span>
                  has its advantage of perfect Insulation, it avoids
                  transmitting the heat to the other chambers which will
                  influence the baking result. We choose
                  <span className="bold">
                    {" "}
                    optimum aluminum plate materials,{" "}
                  </span>
                  excellent
                  <span className="bold"> sealing performance </span>
                  with full welding process.
                </p>
              </div>
            </div>
            <div className="image-section">
              <img src={VTVI} alt="VTVI img" className="img-fluid" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 7*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">SPECIFICATION</h1>
            </div>
            <div className="image-section-brochure">
              <img
                src={EuropeanDeckBorchure}
                alt="EuropeanDeckBorchure img"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 8*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">MODEL</h1>
            </div>
            <div className="image-section-model">
              <img
                src={FirstModel}
                alt="FirstModel img"
                className="img-fluid"
              />
              <img
                src={SecondModel}
                alt="SecondModel img"
                className="img-fluid"
              />
              <img
                src={ThirdModel}
                alt="ThirdModel img"
                className="img-fluid"
              />
              <img
                src={FourthModel}
                alt="FourthModel img"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 9*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">VIDEO</h1>
            </div>
            <div className="video-container">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=-jDyMj1Cg_E"
                controls
                playing
                muted
                loop
                width="100%"
                height="auto"
                className="video-player"
              />
            </div>
          </div>
          <div className="divider"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetail;
