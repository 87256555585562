import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import firstImage from "../assets/img/CombinationOven/firstImage.webp";
import secondImage from "../assets/img/CombinationOven/secondImage.webp";
import thirdImage from "../assets/img/CombinationOven/thirdImage.webp";
import fourthImage from "../assets/img/CombinationOven/fourthImage.webp";
import CombinationDeckOvenBorchure from "../assets/img/CombinationOven/model.png";
import FirstModel from "../assets/img/CombinationOven/model1.webp";
import SecondModel from "../assets/img/CombinationOven/model2.webp";
import ThirdModel from "../assets/img/CombinationOven/model3.webp";
import FourthModel from "../assets/img/CombinationOven/model4.webp";
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SteamStart from "../assets/img/SteamStart.webp";
import BootPpointment from "../assets/img/BootAppointment.webp";
import ConfigurableHeating from "../assets/img/ConfigurableHeating.webp";
import NichromeWire from "../assets/img/NichromeWire.webp";
import LowETempered from "../assets/img/LowETempared.webp";
import VTVI from "../assets/img/VTVI.webp";

const ProductDetail = () => {
  const [sliderFor, setSliderFor] = useState(null);
  const [sliderNav, setSliderNav] = useState(null);

  const sliderForSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: sliderNav,
    className: "main-slider",
  };

  const sliderNavSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: sliderFor,
    dots: true,
    focusOnSelect: true,
    className: "nav-slider",
  };

  const handleSlideClick = (slideno) => {
    sliderNav.slickGoTo(slideno - 1);
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <Container style={{ marginTop: "20px", padding: "20px" }}>
      <Row className="justify-content-md-center">
        <Col className="overview-container" md={12}>
          <h2
            style={{
              color: "#9f0826",
              fontFamily: "Holtwood One SC",
              textAlign: "center",
              margin: "20px 0",
              // background: "red",
              fontSize: "3rem",
            }}
          >
            Combination Oven HBDO
          </h2>
          <p
            style={{
              fontSize: "16px",
              textAlign: "justify",
              marginTop: "10px",
              color: "#9f0826",
              fontFamily: "verdana",
            }}
          >
            Multi-function, Deck Oven, Convection Oven, Proofer
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "justify",
              marginTop: "10px",
              fontFamily: "Holtwood One SC",
            }}
          >
            Bresso Combi-Oven offers the optimal combination for our deck ovens,
            convection, proofer, even for bakeware insert rack. It's
            multi-functional in baking. Combinations help our bakers capable of
            baking all kinds of production demand. Thanks to convenient
            programmed control, easy operation, it's the excellent choice both
            for artisanal and junior bakers
          </p>
        </Col>
        <Col md={12} className="product-image overview-container">
          <h1 style={{ fontWeight: "bolder" }} className="section-title">
            IMAGES
          </h1>
          <div>
            <Slider
              {...sliderForSettings}
              ref={(slider) => setSliderFor(slider)}
            >
              <div className="slickSlide">
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div className="slickSlide">
                <img src={secondImage} alt="Slide 2" />
              </div>
              <div className="slickSlide">
                <img src={thirdImage} alt="Slide 3" />
              </div>
              <div className="slickSlide">
                <img src={fourthImage} alt="Slide 4" />
              </div>
            </Slider>
            <Slider
              {...sliderNavSettings}
              ref={(slider) => setSliderNav(slider)}
            >
              <div onClick={() => handleSlideClick(1)}>
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div onClick={() => handleSlideClick(2)}>
                <img src={secondImage} alt="Slide 2" />
              </div>
              <div onClick={() => handleSlideClick(3)}>
                <img src={thirdImage} alt="Slide 3" />
              </div>
              <div onClick={() => handleSlideClick(4)}>
                <img src={fourthImage} alt="Slide 4" />
              </div>
            </Slider>
          </div>
        </Col>
        <Col md={12} className="overview-container">
          <h2>OVERVIEW</h2>
          <div className="bullet-point advanced-tech">
            Advanced technology of STEAM Heating System, baked bread with best
            sheen.
          </div>

          <div className="bullet-point matrix-heating">
            Baking on optional imported Stone Slab, baked products more crunchy.{" "}
          </div>

          <div className="bullet-point">
            Matrix Heating System with Far Infrared IBS technology.
          </div>

          <div className="bullet-point">
            Unique using LOW-E Tempered Glass, shorten the baking time.
          </div>
          <div className="bullet-point">
            VT&VI Fully welded nano thermal insulation system, Modular design
            with perfect insulation.
          </div>
          <div className="bullet-point">
            Configurable heating levels, best recycleable and insulation
            materials, optimize Energy resource usage.
          </div>
          <div className="bullet-point">
            Convection HCV series, Steam with advanced steam spraying technology
            and core temperature sensor
          </div>
          <div className="bullet-point">
            Proofer HYDC series, with proofer Reservation Function of PCB
            control panel which supports weekly start-up program, from Monday to
            Sunday.
          </div>
          <div className="divider"></div>

          {/* New section */}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">
                Unique Integrated Steam Heating System
              </h1>
              <div className="section-text">
                <p>
                  Thanks to{" "}
                  <span className="bold">Integrated Heating technology</span>,
                  much more <span className="bold">Energy-saving</span>. Option
                  to choose standard separate steam generator. The steam
                  generators are thermostatically controlled, producing{" "}
                  <span className="bold">
                    abundant quantity of high-pressure saturated steam
                  </span>{" "}
                  economically, and rapidly to make the bread with{" "}
                  <span className="bold">best sheen</span>.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            >
              {/* Place your image component here */}
              <img src={SteamStart} alt="Your Image" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 2*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Boot appointment function</h1>
              <div className="section-text">
                <p>
                  Standard oven, it can realize{" "}
                  <span className="bold"> Boot appointment function</span>,
                  before leaving the workshop, when you back to the shop next
                  morning, Bresso oven has been{" "}
                  <span className="bold">preheated</span> according to the time
                  and temperature setting, just looking forward to your baking
                  operation.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            >
              {/* Place your image component here */}
              <img src={BootPpointment} alt="BootPpointment img" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 3*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Configurable Heating Levels</h1>
              <div className="section-text">
                <p>
                  Bresso ovens own{" "}
                  <span className="bold">
                    {" "}
                    efficient configurable heating levels,
                  </span>
                  , firepower of{" "}
                  <span className="bold">large fire, medium fire</span> and{" "}
                  <span className="bold">small fire.</span>
                  Our systems optimize Energy resource usage, avoiding energy
                  losses. Becides we use{" "}
                  <span className="bold">
                    recycleable and insulation materials,
                  </span>{" "}
                  equipped with{" "}
                  <span className="bold">
                    modern control units, digital data management
                  </span>{" "}
                  to realize perfect energy efficiency.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            >
              {/* Place your image component here */}
              <img src={ConfigurableHeating} alt="ConfigurableHeating img" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 4*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Nichrome Wire Heater</h1>
              <div className="section-text">
                <p>
                  <span className="bold"> NICHROME WIRE HEATER, </span>
                  imported from Japan with high quality and combined with
                  <span className="bold">
                    {" "}
                    Advanced IBS technology ( Far Infrared Enamel Treatment),{" "}
                  </span>
                  equipped with
                  <span className="bold"> Matrix Heating System </span>
                  creating a greater heating surface area to guarantee uniform
                  baking. The wire heater
                  <span className="bold"> shorten the baking time </span>
                  by 20%~50%, baking time of 100g bread has been shortened 2
                  minutes. More
                  <span className="bold"> Energy-saving </span>
                  compared to other brand ovens.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            >
              {/* Place your image component here */}
              <img src={NichromeWire} alt="NichromeWire img" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 5*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">LOW-E Tempered Glass</h1>
              <div className="section-text">
                <p>
                  Unparalleled merit of Bresso oven is equipped with
                  <span className="bold"> LOW-E Tempered Glass, </span>
                  heat inside the oven will not dissipate to outside,
                  <span className="bold">
                    {" "}
                    gather all the heat inside the chamber{" "}
                  </span>
                  to shorten the baking time and achieving energy-saving effect.
                  Under baking status the heat from Low-E glass will not let you
                  feel too hot to approach.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            >
              {/* Place your image component here */}
              <img src={LowETempered} alt="LowETempered img" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 6*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">
                VT&VI Fully Welded Nano Thermal Insulation System
              </h1>
              <div className="section-text">
                <p>
                  <span className="bold">
                    {" "}
                    VT&VI Fully Welded Nano Thermal Insulation System,{" "}
                  </span>
                  economic efficiency and flexibility. Each deck is separately
                  modular design, 1-4 decks stack one above the other.
                  <span className="bold"> Modular design </span>
                  has its advantage of perfect Insulation, it avoids
                  transmitting the heat to the other chambers which will
                  influence the baking result. We choose
                  <span className="bold">
                    {" "}
                    optimum aluminum plate materials,{" "}
                  </span>
                  excellent
                  <span className="bold"> sealing performance </span>
                  with full welding process.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            >
              {/* Place your image component here */}
              <img src={VTVI} alt="VTVI img" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 7*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">SPECIFICATION</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section-borchure"
            >
              {/* Place your image component here */}
              <img
                src={CombinationDeckOvenBorchure}
                alt="CombinationDeckOvenBorchure img"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 8*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">MODEL</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section-model"
            >
              {/* Place your image component here */}
              <img src={FirstModel} alt="FirstModel img" />
              <img src={SecondModel} alt="SecondModel img" />
              <img src={ThirdModel} alt="ThirdModel img" />
              <img src={FourthModel} alt="FourthModel img" />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 9*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">VIDEO</h1>
            </div>
            <div
              className="video-container"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <ReactPlayer
                url="https://www.youtube.com/watch?v=yI2EPvvMjRo"
                style={{ marginTop: "30px" }}
                controls
                playing
                muted
                loop
                width="100%"
                height="600px"
              />
            </div>
          </div>
          <div className="divider"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetail;
