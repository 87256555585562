import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import firstImage from "../assets/img/MixureMachine/FirstImage.webp";
import secondImage from "../assets/img/MixureMachine/SecondImage.webp";
import thirdImage from "../assets/img/MixureMachine/ThirdImage.webp";
import MixureMachineSpecification from "../assets/img/MixureMachine/Specification.png";
import FirstModel from "../assets/img/MixureMachine/model1.webp";
import SecondModel from "../assets/img/MixureMachine/model2.webp";
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const ProductDetail = () => {
  const [sliderFor, setSliderFor] = useState(null);
  const [sliderNav, setSliderNav] = useState(null);

  const sliderForSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: sliderNav,
    className: "main-slider",
  };

  const sliderNavSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: sliderFor,
    dots: true,
    focusOnSelect: true,
    className: "nav-slider",
  };

  const handleSlideClick = (slideno) => {
    sliderNav.slickGoTo(slideno - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <Container style={{ marginTop: "20px", padding: "20px" }}>
      <Row className="justify-content-md-center">
        <Col className="overview-container" md={12}>
          <h2
            style={{
              color: "#9f0826",
              fontFamily: "Holtwood One SC",
              textAlign: "center",
              margin: "20px 0",
              // background: "red",
              fontSize: "3rem",
            }}
          >
            Mixer Machine
          </h2>
          <p
            style={{
              fontSize: "16px",
              textAlign: "justify",
              marginTop: "10px",
              color: "#9f0826",
              fontFamily: "verdana",
            }}
          >
            Three-speed variable speed, Multi-layer anti-rust coating treatment,
            Durable and Low noise
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "justify",
              marginTop: "10px",
              fontFamily: "Holtwood One SC",
            }}
          >
            HKM Mixer represents the ideal machine for bakeries and
            confectioners. It is made of thick stainless steel and strong
            mechanical components, with high reliablity performance. Excellent
            mechanical feature make this machine sturdy, reliable and silent,
            with long lifespan.
          </p>
        </Col>
        <Col md={12} className="product-image overview-container">
          <h1 style={{ fontWeight: "bolder" }} className="section-title">
            IMAGES
          </h1>
          <div>
            <Slider
              {...sliderForSettings}
              ref={(slider) => setSliderFor(slider)}
            >
              <div className="slickSlide">
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div className="slickSlide">
                <img src={secondImage} alt="Slide 2" />
              </div>
              <div className="slickSlide">
                <img src={thirdImage} alt="Slide 3" />
              </div>
            </Slider>
            <Slider
              {...sliderNavSettings}
              ref={(slider) => setSliderNav(slider)}
            >
              <div onClick={() => handleSlideClick(1)}>
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div onClick={() => handleSlideClick(2)}>
                <img src={secondImage} alt="Slide 2" />
              </div>
              <div onClick={() => handleSlideClick(3)}>
                <img src={thirdImage} alt="Slide 3" />
              </div>
            </Slider>
          </div>
        </Col>
        <Col md={12} className="overview-container">
          <h2>OVERVIEW</h2>
          <div className="bullet-point advanced-tech">
            Its head section has a special thermoformed and shockproof plastic
            cap.
          </div>

          <div className="bullet-point matrix-heating">
            Transmission gears are made of rectified nickel-chrome-molybdenum
            steel.
          </div>

          <div className="bullet-point">
            Tool-stopping system conforms to CE safety derectives.
          </div>
          <div className="bullet-point">Durable and Low noise</div>
          <div className="bullet-point">Three-speed variable speed</div>
          <div className="bullet-point">
            Multi-layer anti-rust coating treatment
          </div>

          <div className="divider"></div>

          {/* New section */}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Tool-stopping system</h1>
              <div className="section-text">
                <p>
                  Its head section has a special thermoformed and
                  <span className="bold"> shockproof </span>
                  plastic cap. Transmission gears are made of
                  <span className="bold">
                    {" "}
                    rectified nickel-chrome-molybdenum steel.{" "}
                  </span>
                  Tool-stopping system conforms to
                  <span className="bold"> CE safety derectives. </span>
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            ></div>
          </div>
          <div className="divider"></div>
          {/* New section 2*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Vertical design breaker</h1>
              <div className="section-text">
                <p>
                  Vertical design commercial egg breaker, the overall structure
                  is
                  <span className="bold"> stable </span>
                  and
                  <span className="bold"> rigid, </span>
                  equipped with imported
                  <span className="bold"> powerful </span>
                  motors and cooling fans, long-term operation will not
                  overheat, gear thickness increased by 30%, using
                  <span className="bold"> nickel-chromium alloy steel, </span>
                  vacuum treatment and precision grinding,
                  <span className="bold">
                    {" "}
                    durable and Low noise, toothed belt drive system,{" "}
                  </span>
                  can buffer the instant force, protect the gear and motor.
                  <span className="bold"> Three-speed variable speed, </span>
                  flexibly matched with different agitators and speeds according
                  to process requirements, to maximize the economic benefits of
                  one machine for multiple purposes.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            ></div>
          </div>
          <div className="divider"></div>
          {/* New section 3*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">
                National standard food grade SUS#304 stainless steel{" "}
              </h1>
              <div className="section-text">
                <p>
                  The whole machine has undergone
                  <span className="bold">
                    {" "}
                    multi-layer anti-rust coating be made of national standard
                    food grade{" "}
                  </span>
                  treatment,
                  <span className="bold"> SUS#304 stainless steel, </span>
                  which will never rust and is resistant to high temperature or
                  acid and alkali. It has passed the EU electrical safety CE
                  standard certification and complies with the American health
                  NSF standard. It is suitable for food factories, hotel
                  kitchens, etc. As whipping cream or egg white and the multiple
                  mixing uses, such as mixing cake batter, it’s a delicate and
                  practical equipment.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            ></div>
          </div>
          <div className="divider"></div>
          {/* New section 7*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">SPECIFICATION</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section-borchure"
            >
              {/* Place your image component here */}
              <img
                src={MixureMachineSpecification}
                alt="MixureMachineSpecification img"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 8*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">MODEL</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section-model"
            >
              {/* Place your image component here */}
              <img
                style={{ width: "20%", height: "80%", marginRight: "20px" }}
                src={FirstModel}
                alt="FirstModel img"
              />
              <img
                style={{ width: "20%", height: "80%", marginLeft: "20px" }}
                src={SecondModel}
                alt="SecondModel img"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 9*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">VIDEO</h1>
            </div>
            <div
              className="video-container"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <ReactPlayer
                url="https://d1c6gk3tn6ydje.cloudfront.net/1513135116833038336%2Fe1152d45f8acd44eabd0066199366bca.mp4"
                style={{ marginTop: "30px" }}
                controls
                muted
                width="100%"
                height="600px"
              />
            </div>
          </div>
          <div className="divider"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetail;
