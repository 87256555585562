import React from "react";
import ReactPlayer from "react-player";

function VideoPlayer() {
  return (
    <div>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=h_5_xEAvBEU&t=1s"
        style={{ marginTop: "30px" }}
        controls
        playing
        muted
        loop
        width="100%"
        height="600px"
      />
    </div>
  );
}

export default VideoPlayer;
