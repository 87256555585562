import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import firstImage from "../assets/img/refrigeratorProofer/FirstImage.webp";
import secondImage from "../assets/img//refrigeratorProofer/SecondImage.webp";
import thirdImage from "../assets/img//refrigeratorProofer/ThirdImage.webp";
import fourthImage from "../assets/img/refrigeratorProofer/FourthImage.webp";
import RefirigeratorEvenSpecification from "../assets/img/refrigeratorProofer/Specification.png";
import FirstModel from "../assets/img/refrigeratorProofer/model1.webp";
import SecondModel from "../assets/img/refrigeratorProofer/model2.webp";
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const ProductDetail = () => {
  const [sliderFor, setSliderFor] = useState(null);
  const [sliderNav, setSliderNav] = useState(null);

  const sliderForSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: sliderNav,
    className: "main-slider",
  };

  const sliderNavSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: sliderFor,
    dots: true,
    focusOnSelect: true,
    className: "nav-slider",
  };

  const handleSlideClick = (slideno) => {
    sliderNav.slickGoTo(slideno - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <Container style={{ marginTop: "20px", padding: "20px" }}>
      <Row className="justify-content-md-center">
        <Col className="overview-container" md={12}>
          <h2
            style={{
              color: "#9f0826",
              fontFamily: "Holtwood One SC",
              textAlign: "center",
              margin: "20px 0",
              // background: "red",
              fontSize: "3rem",
            }}
          >
            HBDC Refrigerated Proofer
          </h2>
          <p
            style={{
              fontSize: "16px",
              textAlign: "justify",
              marginTop: "10px",
              color: "#9f0826",
              fontFamily: "verdana",
            }}
          >
            Wide temperature, Fermentation parallel System, Reservation Function
            of PCB
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "justify",
              marginTop: "10px",
              fontFamily: "Holtwood One SC",
            }}
          >
            HBDC Refrigerated Proofer automatically runs with wide temperature
            range and huimidity. It realizes process-precision, automatic
            proofing, fast cooling. It's most suitable for freezing and long
            time storage of pre-baked products.
          </p>
        </Col>
        <Col md={12} className="product-image overview-container">
          <h1 style={{ fontWeight: "bolder" }} className="section-title">
            IMAGES
          </h1>
          <div>
            <Slider
              {...sliderForSettings}
              ref={(slider) => setSliderFor(slider)}
            >
              <div className="slickSlide">
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div className="slickSlide">
                <img src={secondImage} alt="Slide 2" />
              </div>
              <div className="slickSlide">
                <img src={thirdImage} alt="Slide 3" />
              </div>
              <div className="slickSlide">
                <img src={fourthImage} alt="Slide 4" />
              </div>
            </Slider>
            <Slider
              {...sliderNavSettings}
              ref={(slider) => setSliderNav(slider)}
            >
              <div onClick={() => handleSlideClick(1)}>
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div onClick={() => handleSlideClick(2)}>
                <img src={secondImage} alt="Slide 2" />
              </div>
              <div onClick={() => handleSlideClick(3)}>
                <img src={thirdImage} alt="Slide 3" />
              </div>
              <div onClick={() => handleSlideClick(4)}>
                <img src={fourthImage} alt="Slide 4" />
              </div>
            </Slider>
          </div>
        </Col>
        <Col md={12} className="overview-container">
          <h2>OVERVIEW</h2>
          <div className="bullet-point advanced-tech">
            Wide temperature rang from -10~45℃
          </div>

          <div className="bullet-point matrix-heating">
            Low &High Temperature Fermentation parallel System{" "}
          </div>

          <div className="bullet-point">
            Proofer Reservation Function of PCB control panel supports weekly
            start-up program
          </div>

          <div className="divider"></div>

          {/* New section */}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Wide Temperature</h1>
              <div className="section-text">
                <p>
                  Bresso proofer is ideal for various long-process proofing
                  methods with its
                  <span className="bold"> wide temperature range </span>
                  from
                  <span className="bold"> wide temperature range </span>
                  It’s fantastic to improve the dough structure and longer
                  freshness, thanks to the lay out design, the products can be
                  stored for up to 36 hours. It is also optional for
                  <span className="bold"> Double-Fermentation </span>
                  Control System or
                  <span className="bold"> Single-Fermentation </span>
                  Control System.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            ></div>
          </div>
          <div className="divider"></div>
          {/* New section 2*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">
                Low &High Temperature Fermentation{" "}
              </h1>
              <div className="section-text">
                <p>
                  Low &High Temperature
                  <span className="bold"> Fermentation parallel System </span>
                  provide you the best solution for both freezing and
                  unfreezing;
                  <span className="bold">
                    {" "}
                    advanced microprocessor control{" "}
                  </span>
                  and keep the correct temperature and humidity ( Indoor
                  humidity ~ 99% ); Frozen products can be defrosted quickly by
                  its excellent heating system.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            ></div>
          </div>
          <div className="divider"></div>
          {/* New section 3*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">PCB control panel </h1>
              <div className="section-text">
                <p>
                  According to your requirement of everyday's
                  <span className="bold"> baking schedule, </span>
                  The Proofer Reservation Function of PCB control panel supports
                  <span className="bold"> weekly start-up program, </span>
                  from Monday to Sunday. Automatic input of time and
                  temperature, it’s
                  <span className="bold"> easy operation. </span>
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section"
            ></div>
          </div>
          <div className="divider"></div>
          {/* New section 7*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">SPECIFICATION</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section-borchure"
            >
              {/* Place your image component here */}
              <img
                src={RefirigeratorEvenSpecification}
                alt="RefirigeratorEvenSpecification img"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 8*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">MODEL</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section-model"
            >
              {/* Place your image component here */}
              <img
                style={{ width: "20%", height: "80%", marginRight: "20px" }}
                src={FirstModel}
                alt="FirstModel img"
              />
              <img
                style={{ width: "20%", height: "80%", marginLeft: "20px" }}
                src={SecondModel}
                alt="SecondModel img"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 9*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">VIDEO</h1>
            </div>
            <div
              className="video-container"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <ReactPlayer
                url="https://d1c6gk3tn6ydje.cloudfront.net/1513135116833038336%2Fe1152d45f8acd44eabd0066199366bca.mp4"
                style={{ marginTop: "30px" }}
                controls
                muted
                loop
                width="100%"
                height="600px"
              />
            </div>
          </div>
          <div className="divider"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetail;
