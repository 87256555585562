import React from "react";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
} from "./FooterStyles";

export const Footer = () => {
  return (
    <Box id="footer" style={{ position: "relative", button: 0 }}>
      <Container>
        <Row>
          <Column>
            <Heading>Join Us</Heading>
            <FooterLink href="#">Github</FooterLink>
            <FooterLink href="#">Discord</FooterLink>
          </Column>
          <Column>
            <Heading>Community</Heading>
            <FooterLink href="#">
              <i className="fab fa-facebook-f">
                <span style={{ marginLeft: "10px" }}>Telegram</span>
              </i>
            </FooterLink>
            <FooterLink href="#">
              <i className="fab fa-twitter">
                <span style={{ marginLeft: "10px" }}>Twitter</span>
              </i>
            </FooterLink>
            <FooterLink href="#">
              <i className="fab fa-instagram">
                <span style={{ marginLeft: "10px" }}>Medium</span>
              </i>
            </FooterLink>
            <FooterLink href="#">
              <i className="fab fa-youtube">
                <span style={{ marginLeft: "10px" }}>Youtube</span>
              </i>
            </FooterLink>
            <FooterLink href="#">
              <i className="fab fa-twitter">
                <span style={{ marginLeft: "10px" }}>Gravity</span>
              </i>
            </FooterLink>
            <FooterLink href="#">
              <i className="fab fa-youtube">
                <span style={{ marginLeft: "10px" }}>LinkedIn</span>
              </i>
            </FooterLink>
          </Column>
          <Column>
            <Heading>General</Heading>
            <FooterLink href="#aboutUs">About us</FooterLink>
            <FooterLink href="#benefits">Benefits</FooterLink>
            <FooterLink href="#product">Product</FooterLink>
            <FooterLink href="#contact">Contact Us</FooterLink>
          </Column>
        </Row>
      </Container>
    </Box>
  );
};
