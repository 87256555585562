import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import "animate.css";
import TrackVisibility from "react-on-screen";
import BridgeMarquee from "./Marquee";
import VideoPlayer from "./Video";
// import Carousel from "./Carousel";

export const Banner = () => {
  return (
    <section className="banner" id="aboutUs">
      <Container>
        <Row className="align-items-center">
          <Col xs={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h1 className="banner-title">Who is Viva Appliances?</h1>
                  <p
                    className="pShiningAnimation"
                    style={{ textAlign: "justify" }}
                  >
                    Viva Appliances is the exclusive distributor of Bresso
                    International trade in Australia and New Zealand. Bresso,
                    established in 1991, specializes in high-quality baking
                    technology and equipment. Their product range is extensive
                    and includes state-of-the-art bakery deck ovens, convection
                    ovens, combination ovens, refrigerated proofers, kneading
                    machines, and more. These products are designed with
                    advanced features like infrared baking systems, steam
                    heating, and energy-efficient designs, ensuring optimal
                    performance and reliability for professional bakers.
                    Bresso's commitment to innovation and quality makes them a
                    preferred choice for baking professionals aiming to produce
                    superior baked goods.
                  </p>
                  <p
                    className="pShiningAnimation"
                    style={{ textAlign: "justify" }}
                  >
                    Our showroom is conveniently located in Wahroonga, New South
                    Wales, and is open by appointment only, allowing for a
                    personalized and in-depth exploration of our comprehensive
                    range of Bresso products.
                  </p>
                  <VideoPlayer />
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
