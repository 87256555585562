import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import BakeryDeck from "../assets/img/bresso1991/bakeryDeck.webp";
import EuropeanDeck from "../assets/img/bresso1991/EuropeanDeck.webp";
import CombinationOven from "../assets/img/bresso1991/CombinationOven.webp";
import ConvectionOven from "../assets/img/bresso1991/ConventionOven.webp";
import RefigeratedProofer from "../assets/img/bresso1991/RefrigeretedProofer.webp";
import KneadingMachine from "../assets/img/bresso1991/kneadingMachine.webp";
import MixerMachine from "../assets/img/bresso1991/MixerMachine.webp";
import SheeterMachine from "../assets/img/bresso1991/SheeterMachine.webp";
import ScrollToTopBtn from "./ScrollToTop";
import { Link } from "react-router-dom";

export const Services = () => {
  const [activeLink, setActiveLink] = useState("home");
  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };
  function getCurrentURL() {
    return window.location.href;
  }
  const url = getCurrentURL();
  console.log("url", url);
  const myArray = url.split("/");
  console.log("myArray", myArray[myArray.length - 1]);
  return (
    <section className="services" id="product">
      <Container>
        <Row className="fullServices w-100 align-items-center justify-content-center">
          <Row className="servicesTitle">
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <h2
                style={{
                  fontSize: "45px",
                  fontWeight: "700",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Our Products
              </h2>
              <div className="lineServices"></div>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "20px",
            }}
          >
            <Col className="cardServices" md={3}>
              <Link to="/product_detail">
                <Col className="first" md={12}>
                  <img
                    src={BakeryDeck}
                    height={500}
                    width={500}
                    alt="Header Img"
                  />
                  <div className="priceOverlay">
                    <h4
                      style={{
                        color: "rgba(159, 8, 38, 1)",
                        fontFamily: "Holtwood One SC",
                        textAlign: "center",
                      }}
                    >
                      Bakery Deck Oven HBDO
                    </h4>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                    >
                      HBDO Deck ovens bring flexibility and reliablity for
                      quality-oriented bakers, offer the best baking capacity in
                      the smallest space. Thanks to equally radiated heat and a
                      static baking atmosphere, with excellent baking results of
                      crisp crusts, succulent crumb, crust crack.
                    </p>
                  </div>
                </Col>
              </Link>
            </Col>
            <Col className="cardServices" md={3}>
              <Link to="/product_detail2">
                <Col className="first" md={12}>
                  <img
                    src={EuropeanDeck}
                    height={500}
                    width={500}
                    alt="Header Img"
                  />
                  <div className="priceOverlay">
                    <h4
                      style={{
                        color: "rgba(159, 8, 38, 1)",
                        fontFamily: "Holtwood One SC",
                        textAlign: "center",
                      }}
                    >
                      European [W] Deck Oven HBWO
                    </h4>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                    >
                      The HBWO model is the fantastic deck oven for artisanal
                      bakers. Optimum for baking the entire product range, from
                      heavy rye breads to wheat rolls. HBWO Ovens stand out the
                      advantages of lower power consumption, convenient
                      programmed control, low maintenance, durability and easy
                      operation.
                    </p>
                  </div>
                </Col>
              </Link>
            </Col>
            <Col className="cardServices" md={3}>
              <Link to="/product_detail3">
                <Col className="first" md={12}>
                  <img
                    src={CombinationOven}
                    height={500}
                    width={500}
                    alt="Header Img"
                  />
                  <div className="priceOverlay">
                    <h4
                      style={{
                        color: "rgba(159, 8, 38, 1)",
                        fontFamily: "Holtwood One SC",
                        textAlign: "center",
                      }}
                    >
                      Combination Oven HBDO
                    </h4>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                    >
                      Bresso Combi-Oven offers the optimal combination for our
                      deck ovens, convection, proofer, even for bakeware insert
                      rack. It's multi-functional in baking. Combinations help
                      our bakers capable of baking all kinds of production
                      demand. Thanks to convenient programmed control, easy
                      operation, it's the excellent choice both for artisanal
                      and junior bakers
                    </p>
                  </div>
                </Col>
              </Link>
            </Col>
            <Col className="cardServices" md={3}>
              <Link to="/product_detail4">
                <Col className="first" md={12}>
                  <img
                    src={ConvectionOven}
                    height={500}
                    width={500}
                    alt="Header Img"
                  />
                  <div className="priceOverlay">
                    <h4
                      style={{
                        color: "rgba(159, 8, 38, 1)",
                        fontFamily: "Holtwood One SC",
                        textAlign: "center",
                      }}
                    >
                      Convection Oven
                    </h4>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                    >
                      HCV Convection Oven with luxurious design, the best
                      performance in the Korean baking industry. The optimum
                      choice for baking French bread, cream bread, biscuits,
                      pies and other European bread.
                    </p>
                  </div>
                </Col>
              </Link>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "20px",
            }}
          >
            <Col className="cardServices" md={3}>
              <Link to="/product_detail5">
                <Col className="first" md={12}>
                  <img
                    src={RefigeratedProofer}
                    height={500}
                    width={500}
                    alt="Header Img"
                  />
                  <div className="priceOverlay">
                    <h4
                      style={{
                        color: "rgba(159, 8, 38, 1)",
                        fontFamily: "Holtwood One SC",
                        textAlign: "center",
                      }}
                    >
                      Refrigerated Proofer
                    </h4>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                    >
                      HBDC Refrigerated Proofer automatically runs with wide
                      temperature range and huimidity. It realizes
                      process-precision, automatic proofing, fast cooling. It's
                      most suitable for freezing and long time storage of
                      pre-baked products.
                    </p>
                  </div>
                </Col>
              </Link>
            </Col>
            <Col className="cardServices" md={3}>
              <Link to="/product_detail6">
                <Col className="first" md={12}>
                  <img
                    src={KneadingMachine}
                    height={500}
                    width={500}
                    alt="Header Img"
                  />
                  <div className="priceOverlay">
                    <h4
                      style={{
                        color: "rgba(159, 8, 38, 1)",
                        fontFamily: "Holtwood One SC",
                        textAlign: "center",
                      }}
                    >
                      Kneading Machine
                    </h4>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                    >
                      HKPM Kneading Machine is automatic spiral mixer, the ideal
                      machine for bakeries and confectioners. It has three dough
                      capacities for your option from 25kgs to 100kgs. HKPM
                      mixer is made of thick stainless steel, high reliability
                      with strong mechanical components.
                    </p>
                  </div>
                </Col>
              </Link>
            </Col>
            <Col className="cardServices" md={3}>
              <Link to="/product_detail7">
                <Col className="first" md={12}>
                  <img
                    src={MixerMachine}
                    height={500}
                    width={500}
                    alt="Header Img"
                  />
                  <div className="priceOverlay">
                    <h4
                      style={{
                        color: "rgba(159, 8, 38, 1)",
                        fontFamily: "Holtwood One SC",
                        textAlign: "center",
                      }}
                    >
                      Mixer Machine
                    </h4>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                    >
                      HKM Mixer represents the ideal machine for bakeries and
                      confectioners. It is made of thick stainless steel and
                      strong mechanical components, with high reliablity
                      performance. Excellent mechanical feature make this
                      machine sturdy, reliable and silent, with long lifespan.
                    </p>
                  </div>
                </Col>
              </Link>
            </Col>
            <Col className="cardServices" md={3}>
              <Link to="/product_detail8">
                <Col className="first" md={12}>
                  <img
                    src={SheeterMachine}
                    height={500}
                    width={500}
                    alt="Header Img"
                  />
                  <div className="priceOverlay">
                    <h4
                      style={{
                        color: "rgba(159, 8, 38, 1)",
                        fontFamily: "Holtwood One SC",
                        textAlign: "center",
                      }}
                    >
                      Sheeter/ Pastry Machine
                    </h4>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                    >
                      HKDS Sheeter offers the possibility to suit the operators
                      needs, starting from a basic version with painted steel
                      frame up to a version with flour duster, stress free
                      automatic dough reeler, cutting device and stainless steel
                      (ANIS304) and aluminium structure.
                    </p>
                  </div>
                </Col>
              </Link>
            </Col>
          </Row>
        </Row>
      </Container>
      {/* <a className={`${myArray[myArray.length - 1]==='#home' || null ? 'displayNone' : 'toTopButton'}`} href="#home"><img src={arrowUp} height={50} width={50} alt="arrow Img"/></a> */}
      <ScrollToTopBtn />
    </section>
  );
};
