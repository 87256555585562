import React from "react";
import "./App.css";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Contact } from "./components/Contact";
import "bootstrap/dist/css/bootstrap.min.css";
import { Footer } from "./components/Footer";
import { ScrollTop } from "primereact/scrolltop";
import { Services } from "./components/Services";
import { Benefits } from "./components/Benefits";
import Carousel from "./components/Carousel";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProductDetail from "./components/ProductDetail";
import ProductDetail2 from "./components/ProductDetail2";
import ProductDetail3 from "./components/productDetail3";
import ProductDetail4 from "./components/productDetail4";
import ProductDetail5 from "./components/productDetail5";
import ProductDetail6 from "./components/productDetail6";
import ProductDetail7 from "./components/productDetail7";
import ProductDetail8 from "./components/productDetail8";

function App() {
  return (
    <Router>
      <Routes>
        {/* Main layout route */}
        <Route
          path="/"
          element={
            <>
              <NavBar />
              <Carousel />
              <Banner />
              <div className="App">
                <div className="mainBody">
                  <Benefits />
                  <Services />
                  <Contact />
                  <Footer />
                </div>
                <ScrollTop
                  target="parent"
                  threshold={100}
                  className="w-2rem h-2rem border-round bg-primary"
                  icon="pi pi-arrow-up text-base"
                />
              </div>
            </>
          }
        />

        {/* Product detail route */}
        <Route path="/product_detail" element={<ProductDetail />} />
        <Route path="/product_detail2" element={<ProductDetail2 />} />
        <Route path="/product_detail3" element={<ProductDetail3 />} />
        <Route path="/product_detail4" element={<ProductDetail4 />} />
        <Route path="/product_detail5" element={<ProductDetail5 />} />
        <Route path="/product_detail6" element={<ProductDetail6 />} />
        <Route path="/product_detail7" element={<ProductDetail7 />} />
        <Route path="/product_detail8" element={<ProductDetail8 />} />
      </Routes>
    </Router>
  );
}

export default App;
