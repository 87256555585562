import Carousel from "react-bootstrap/Carousel";
import FirstCarouselImage from "../assets/img/bresso1991/one.webp";
import SecondCarouselImage from "../assets/img/bresso1991/two.webp";
import ThirdCarouselImage from "../assets/img/bresso1991/three.webp";
import FourthCarouselImage from "../assets/img/bresso1991/four.webp";
import TrackVisibility from "react-on-screen";

export const Carousel2 = () => {
  return (
    <section className="banner2" id="home">
      <TrackVisibility>
        {({ isVisible }) => (
          <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
            <Carousel fade className="carousel" pause={false}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={FirstCarouselImage}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={SecondCarouselImage}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ThirdCarouselImage}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={FourthCarouselImage}
                  alt="Fourth slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        )}
      </TrackVisibility>
    </section>
  );
};

export default Carousel2;
