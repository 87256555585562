import React from "react";
import { Container, Row } from "react-bootstrap";
import StickyBox from "react-sticky-box";
import { motion, Variants } from "framer-motion";
import easyInstallation from "../assets/img/easy-installation.png";
import customize from "../assets/img/customize.png";
import paymentMethods from "../assets/img/payment-methods.png";
import openSource from "../assets/img/open-source.png";
import variousDatasource from "../assets/img/various-datasource.png";
import flexible from "../assets/img/flexible.png";

const cardVariants = {
  offscreen: {
    y: 100,
  },
  onscreen: {
    y: -100,
    rotate: 0,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1.3,
    },
  },
};

export const Benefits = () => {
  return (
    <>
      <section className="benefits" id="benefits">
        <Container>
          <Row className="align-items-center">
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <StickyBox offsetTop={320} className="benefitsSticky">
                <h1 style={{ fontWeight: "bolder" }}>Why Viva Appliances</h1>
              </StickyBox>
              <div class="benefitsCard wrap">
                <div class="wrap-right">
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: false, amount: 0.2 }}
                  >
                    <motion.div variants={cardVariants}>
                      <div class="benefitsCard">
                        <h1 className="benefitsTitle">
                          <img width={50} height={50} src={easyInstallation} />{" "}
                          Reliability
                        </h1>
                        <p className="benefitsBody">
                          Viva Appliances offers products manufactured by Bresso
                          International, a company known for its reliable and
                          durable baking equipment. With South Korean technology
                          integrated into Chinese craftsmanship, our appliances
                          are built to perform consistently over time, ensuring
                          reliable operation for your business.
                        </p>
                      </div>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: false, amount: 0.2 }}
                  >
                    <motion.div variants={cardVariants}>
                      <div class="benefitsCard">
                        <h1 className="benefitsTitle">
                          <img width={50} height={50} src={customize} /> Quality
                        </h1>
                        <p className="benefitsBody">
                          Our appliances are designed with high-quality
                          materials and undergo rigorous quality control
                          processes to meet international standards. From
                          precision engineering to strict manufacturing
                          protocols, Viva Appliances delivers products that meet
                          or exceed expectations, providing you with top-notch
                          performance in your kitchen.
                        </p>
                      </div>
                    </motion.div>
                  </motion.div>

                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: false, amount: 0.2 }}
                  >
                    <motion.div variants={cardVariants}>
                      <div class="benefitsCard">
                        <h1 className="benefitsTitle">
                          <img width={50} height={50} src={paymentMethods} />{" "}
                          Innovation
                        </h1>
                        <p className="benefitsBody">
                          Bresso International continuously invests in research
                          and development to bring the latest innovations in
                          baking technology to our customers. From advanced
                          heating systems to intuitive control interfaces, our
                          appliances incorporate cutting-edge features that
                          enhance efficiency, productivity, and convenience in
                          your baking operations.
                        </p>
                      </div>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: false, amount: 0.2 }}
                  >
                    <motion.div variants={cardVariants}>
                      <div class="benefitsCard">
                        <h1 className="benefitsTitle">
                          <img width={50} height={50} src={openSource} />{" "}
                          Versatility
                        </h1>
                        <p className="benefitsBody">
                          Whether you need a deck oven for artisan bread baking,
                          a convection oven for pastry production, or a spiral
                          mixer for dough preparation, Viva Appliances offers a
                          comprehensive range of baking equipment to suit your
                          diverse needs. Our versatile appliances are adaptable
                          to various baking styles and recipes, empowering you
                          to unleash your creativity in the kitchen.
                        </p>
                      </div>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: false, amount: 0.2 }}
                  >
                    <motion.div variants={cardVariants}>
                      <div class="benefitsCard">
                        <h1 className="benefitsTitle">
                          <img width={50} height={50} src={variousDatasource} />{" "}
                          Support
                        </h1>
                        <p className="benefitsBody">
                          At Viva Appliances, we prioritize customer
                          satisfaction and provide exceptional support
                          throughout the purchasing process and beyond. From
                          expert guidance in selecting the right equipment for
                          your business to comprehensive after-sales service,
                          including warranty coverage and technical assistance,
                          we are committed to ensuring your success with our
                          products.
                        </p>
                      </div>
                    </motion.div>
                  </motion.div>

                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: false, amount: 0.2 }}
                  >
                    <motion.div variants={cardVariants}>
                      <div class="benefitsCard">
                        <h1 className="benefitsTitle">
                          <img width={50} height={50} src={flexible} />{" "}
                          Efficiency
                        </h1>
                        <p className="benefitsBody">
                          Our appliances are designed to streamline your baking
                          processes, helping you optimize productivity and
                          minimize downtime. Whether it's rapid heating, precise
                          temperature control, or energy-efficient operation,
                          Viva Appliances is dedicated to maximizing efficiency
                          in your kitchen, allowing you to focus on creating
                          delicious baked goods for your customers.
                        </p>
                      </div>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: false, amount: 0.2 }}
                  >
                    <motion.div variants={cardVariants}>
                      <div class="benefitsCard">
                        <h1 className="benefitsTitle">
                          <img width={50} height={50} src={flexible} /> Warranty
                        </h1>
                        <p className="benefitsBody">
                          With a standard two-year warranty in Australia and New
                          Zealand, Viva Appliances provides peace of mind,
                          knowing that your investment is protected against
                          manufacturing defects. Our commitment to quality and
                          reliability extends beyond the point of purchase,
                          ensuring that you can rely on our appliances for years
                          to come.
                        </p>
                      </div>
                    </motion.div>
                  </motion.div>

                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: false, amount: 0.2 }}
                  >
                    <motion.div variants={cardVariants}>
                      <div class="benefitsCard">
                        <h1 className="benefitsTitle">
                          <img width={50} height={50} src={variousDatasource} />{" "}
                          Affordability
                        </h1>
                        <p className="benefitsBody">
                          At Viva Appliances, we offer premium baking products
                          that are competitive in quality with top European
                          brands but come at far more reasonable prices. Our
                          exclusive partnership with Bresso International allows
                          us to provide cutting-edge baking technology that
                          meets the high standards of the industry, ensuring
                          that our customers receive the best value for their
                          investment. Choose Viva Appliances for a combination
                          of top-tier quality and affordability that will
                          enhance your baking operations.
                        </p>
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Benefits;
