import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import { motion, useScroll, useSpring } from "framer-motion";
import logoImage from "../assets/img/vivilogo/vivaLOgo.png";

const icon = {
  hidden: {
    pathLength: 0,
    fill: "rgba(246,186,24,0.20608546836703427) 50%",
  },
  visible: {
    pathLength: 1,
    fill: "#e8b11f",
  },
};
export const NavBar = () => {
  const items = [
    {
      label: "Features",
      icon: "pi pi-fw pi-file",
      items: [
        {
          label: "New",
          icon: "pi pi-fw pi-plus",
          items: [
            {
              label: "Bookmark",
              icon: "pi pi-fw pi-bookmark",
            },
            {
              label: "Video",
              icon: "pi pi-fw pi-video",
            },
          ],
        },
      ],
    },
  ];
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onscroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };
  return (
    <>
      <motion.div className="progress-bar" style={{ scaleX }}></motion.div>
      <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="#home">
            <img
              className="navbarLogo"
              style={{ width: "100%", height: "100%" }}
              src={logoImage}
              alt="Logo"
              fill="red"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                href="#home"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("home")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="#aboutUs"
                className={
                  activeLink === "aboutUs"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("aboutUs")}
              >
                About
              </Nav.Link>
              <Nav.Link
                href="#benefits"
                className={
                  activeLink === "benefits"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("benefits")}
              >
                Benefits
              </Nav.Link>
              <Nav.Link
                href="#product"
                className={
                  activeLink === "product"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("product")}
              >
                Product
              </Nav.Link>
              {/* <Nav.Link
                href="#support"
                className={
                  activeLink === "support"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("support")}
              >
                Support
              </Nav.Link> */}

              {/* <Nav.Link
                href="#statistics"
                className={
                  activeLink === "statistics" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("statistics")}
              >
                Statistics
              </Nav.Link> */}
              {/* <Nav.Link
                href="#benefits"
                className={
                  activeLink === "benefits"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("benefits")}
              >
                Benefits
              </Nav.Link> */}
              {/* <Nav.Link
                href="#services"
                className={
                  activeLink === "services"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("services")}
              >
                Services
              </Nav.Link> */}
              {/* <Nav.Link
                href="#roadmap"
                className={
                  activeLink === "roadmap"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("roadmap")}
              >
                RoadMap
              </Nav.Link> */}
              {/* <NavDropdown
                    title="Features"
                    id={`offcanvasNavbarDropdown-expand-lg`}
                  >
                    <NavDropdown.Item href="#action3">Token Bridge</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Staking
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      ChatGPT AI
                    </NavDropdown.Item>
                  </NavDropdown> */}
              <Nav.Link
                href="#contact"
                className={
                  activeLink === "contact"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("contact")}
              >
                Contact Us
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="#">
                  <img src={navIcon1} alt="" />
                </a>
                <a href="#">
                  <img src={navIcon2} alt="" />
                </a>
                <a href="#">
                  <img src={navIcon3} alt="" />
                </a>
              </div>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
