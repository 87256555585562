import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import firstImage from "../assets/img/PastryMachine/FirstImage.webp";
import PastryMachineSpecification from "../assets/img/PastryMachine/Specification.png";
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const ProductDetail = () => {
  const [sliderFor, setSliderFor] = useState(null);
  const [sliderNav, setSliderNav] = useState(null);

  const sliderForSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: sliderNav,
    className: "main-slider",
  };

  const sliderNavSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: sliderFor,
    dots: true,
    focusOnSelect: true,
    className: "nav-slider",
  };

  const handleSlideClick = (slideno) => {
    sliderNav.slickGoTo(slideno - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <Container style={{ marginTop: "20px", padding: "20px" }}>
      <Row className="justify-content-md-center">
        <Col className="overview-container" md={12}>
          <h2
            style={{
              color: "#9f0826",
              fontFamily: "Holtwood One SC",
              textAlign: "center",
              margin: "20px 0",
              // background: "red",
              fontSize: "3rem",
            }}
          >
            Sheeter/ Pastry Machine
          </h2>
          <p
            style={{
              fontSize: "16px",
              textAlign: "justify",
              marginTop: "10px",
              color: "#9f0826",
              fontFamily: "verdana",
            }}
          >
            Sturdy structure, Folding structure, Safety operation
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "justify",
              marginTop: "10px",
              fontFamily: "Holtwood One SC",
            }}
          >
            HKDS Sheeter offers the possibility to suit the operators needs,
            starting from a basic version with painted steel frame up to a
            version with flour duster, stress free automatic dough reeler,
            cutting device and stainless steel (ANIS304) and aluminium
            structure.
          </p>
        </Col>
        <Col md={12} className="product-image overview-container">
          <h1 style={{ fontWeight: "bolder" }} className="section-title">
            IMAGES
          </h1>
          <div>
            <Slider
              {...sliderForSettings}
              ref={(slider) => setSliderFor(slider)}
            >
              <div className="slickSlide">
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div className="slickSlide">
                <img src={firstImage} alt="Slide 2" />
              </div>
              <div className="slickSlide">
                <img src={firstImage} alt="Slide 3" />
              </div>
            </Slider>
            <Slider
              {...sliderNavSettings}
              ref={(slider) => setSliderNav(slider)}
            >
              <div onClick={() => handleSlideClick(1)}>
                <img src={firstImage} alt="Slide 1" />
              </div>
              <div onClick={() => handleSlideClick(2)}>
                <img src={firstImage} alt="Slide 2" />
              </div>
              <div onClick={() => handleSlideClick(3)}>
                <img src={firstImage} alt="Slide 3" />
              </div>
            </Slider>
          </div>
        </Col>
        <Col md={12} className="overview-container">
          <h2>OVERVIEW</h2>
          <div className="bullet-point advanced-tech">
            Suitable for pastry and rolling dough
          </div>

          <div className="bullet-point matrix-heating">
            Safety operation, hygiene, easy to clean
          </div>

          <div className="bullet-point">
            Sturdy structure, excellent stability, long lifespan
          </div>

          <div className="bullet-point">Folding structure, saving space</div>

          <div className="divider"></div>

          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">Product Details</h1>
            </div>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              className="productDetails-section"
            >
              <ul>
                <li className="productDetails-list">
                  It’s suitable for pastry and rolling dough.
                </li>
                <li>Safety operation, hygiene, easy to clean.</li>
                <li className="productDetails-list">
                  Using imported Motor accessories with long lifespan.
                </li>
                <li className="productDetails-list">
                  High quality, sturdy structure, excellent stability, long
                  lifespan.
                </li>
                <li className="productDetails-list">
                  Folding structure, saving space.
                </li>
                <li className="productDetails-list">
                  Imported conveyor, Lint-free, Friction resistance.
                </li>
                <li className="productDetails-list">
                  Using imported motor parts, extend lifespan.
                </li>
                <li className="productDetails-list">
                  The roller is made of seamless steel pipe, which is processed
                  by computer lathe, grinding, hard chrome plating, etc., the
                  surface of the roller is not sticky, and it is not easy to
                  scratch.
                </li>
              </ul>
            </div>
          </div>
          <div className="divider"></div>

          {/* New section 7*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">SPECIFICATION</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-section-borchure"
            >
              {/* Place your image component here */}
              <img
                src={PastryMachineSpecification}
                alt="PastryMachineSpecification img"
              />
            </div>
          </div>
          <div className="divider"></div>
          {/* New section 9*/}
          <div className="section-container">
            <div className="main-section">
              <h1 className="section-title">VIDEO</h1>
            </div>
            <div
              className="video-container"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <ReactPlayer
                url="https://d1c6gk3tn6ydje.cloudfront.net/1513135116833038336%2Fe1152d45f8acd44eabd0066199366bca.mp4"
                style={{ marginTop: "30px" }}
                controls
                muted
                loop
                width="100%"
                height="600px"
              />
            </div>
          </div>
          <div className="divider"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetail;
